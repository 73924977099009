// 詳細画面
import React from "react"
import "../styles/post.css"

import Layout from "../components/layout";
//import { Link } from "gatsby"
import Seo from "../components/seo"
import "../styles/common/common.css"

export default function Post({ pageContext }) {
    const { title, updatedAt ,createdAt} = pageContext.post;
    const body = pageContext.post.body.childMarkdownRemark.html;
    let description = pageContext.post.description?.internal.content;
    if(!description){
        description = title;
    }

    let keywords = pageContext.post.keywords?.internal.content;
    if(!keywords){
        keywords = "";
    }

    return (
        <Layout>
            <Seo title={title} description={description} keywords={keywords}/>
            <div className="post-header">
                <h1><span>{title}</span></h1>
            </div>
            <div dangerouslySetInnerHTML={{ __html: body }} className="post-body" />
            <p className="post-date">作成日時 : {createdAt} 　　 更新日時 : {updatedAt}</p>
        </Layout>
    )
}